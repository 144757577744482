@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "JetBrains Mono", sans-serif;
  box-sizing: border-box;
}

body {
  background: black;
  /* overflow: hidden; */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  color: white;
}

.logo svg {
  margin-top: 5px;
  margin-right: 10px;
}

.logo-text {
  font-size: 20px;
}

.logo-text1 {
  font-size: 20px;
  color: #00ff99;
}


/* .logo::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 0.4s;
} */


.navbar a {
  font-size: 14px;
  color: white;
  text-decoration: none;
  font-weight: 500;
  margin-left: 35px;
  position: relative; /* Add position relative to create a stacking context */
}

.navbar a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px; /* Adjust the value to position the line */
  width: 100%;
  height: 2px;
  background-color: #00cc7f;
  transform: scaleX(0); /* Initially set the line width to 0 */
  transform-origin: left; /* Set the origin to the left for scaling effect */
  transition: transform 0.3s ease; /* Add a transition effect */
}

.navbar a:hover::after{
/* .navbar a.active::after { */
  transform: scaleX(1); /* Scale the line width to 100% on hover/active */
}

.navbar i{
  color: #00cc7f;
}

.container {
  display: inline-block; /* Ensure the container only takes up necessary space */
}

.link-text {
  vertical-align: middle; /* Align the text vertically with the icon */
}

.icon-container {
  display: inline-block;
  opacity: 0; /* Initial opacity */
  transition: opacity 0.3s ease; /* Add a smooth transition for the fading effect */
}

.link-text:hover + .icon-container,
.icon-container:hover{
  opacity: 1; /* Lower opacity on hover */
}


section {
  height: 100vh;
}

.text-cursor{
  user-select: none;
  cursor: default;
}
.home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* padding: 0 10%; */
  overflow: hidden;
}

.home-content h1 {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 56px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center; /* Horizontally center the text */
  color: white;
}

.home-content h1::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 1s;
}

.home-content h3 {
  position: relative;
  margin: 15px 0 15px;
  font-size: 32px;
  font-weight: 500;
  color: white;
}

.home-content h3::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 1.3s;
}

.home-content p {
  position: relative;
  margin: 20px 0 40px;
  font-weight: 300;
  font-size: 18px;
  max-width: 850px;
  color: white;
}

.home-content p span {
  font-weight: 900;
  color: #00ff99;
}

/* .home-content p span:hover {
  color: cyan;
  cursor: default;
} */
.home-content p::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 1.6s;
}

.home-content .btn-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 345px;
  height: 50px;
}

.home-content .btn-box::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 1.9s;
  z-index: 2;
}

.btn-box a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100%;
  background: black;
  border: 3px solid #00ff99;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 1px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.btn-box a:hover {
  color: black;
}

.btn-box a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #00ff99;
  z-index: -1;
  transition: 0.5s;
}
.btn-box a:hover::before {
  width: 100%;
}

.home-sci {
  position: absolute;
  bottom: 40px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-sci::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  animation: showRight 1s ease forwards;
  animation-delay: 2.1s;
  z-index: 2;
}
.home-sci a {
  position: relative;
  display: inline-flex;
  justify-self: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  font-size: 30px;
  color: white;
  text-decoration: none;
  transition: transform 0.3s ease;
}
.home-sci a:hover {
  transform: scale(1.1) translateY(-5px);
}

.home-sci a:hover::after {
  content: attr(data-social);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 12px;
  /* background-color: #000; */
  color: white;
  font-size: 14px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.home-sci a:hover::after {
  opacity: 1;
}

/* KEYFRAMES ANIMATION */

@keyframes showRight {
  100% {
    width: 0;
  }
}

.quote-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* padding: 0 10%; */
  background-color: yellow;
}

.quote{
  margin-left: 10%;
  margin-right: 10%;
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* padding: 0 10%; */
  background-color: #00cc7f;
}
/* 
.Projects {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 3rem;
  padding: 10rem;
} */
/* .project-content {
  height: 15rem;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #52cdc9;
  grid-column: span 3;
  box-shadow: 0.7rem 0.7rem 0 black;
  border: 5px solid black;
  gap: 2rem;
} */

/* .project-content p {
  display: none;
  padding: 0 2rem;
}

.project-content.active p {
  display: block;
  padding: 0 2rem;
}

.project-content.active {
  grid-column: 1 / span 6;
  grid-row: 1;
  order: 0;
  height: auto;
} */

/* .project-content.is-inactive {
  grid-column: span 2;
} */
.projects-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 100px 20px; /* Adjusted padding to fit content better */
  background-color: #000000;
  height: 100vh;
}

.project-card {
  display: flex;
  align-items: center;
  width: calc(50% - 100px); /* Adjusted width to make the boxes shorter and fit within gap */
  background-color: #000000;
  margin: 10px; /* Reduced margin for tighter packing */
  padding: 0px 80px 80px 80px; /* Adjusted padding for tighter packing */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  position: relative; /* Add relative positioning for the line */
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.project-card:hover {
  color: #00ff99;
}

.project-number {
  font-size: 28px;
  font-weight: 800;
  margin-right: 20px;

}

.project-info {
  flex: 1;
}

.project-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-description {
  font-size: 14px;
}

.project-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 22px;
  background-color: white;
  color: black; /* Default icon color */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for icon */
}

.project-icon svg {
  transition: transform 0.3s ease; /* Smooth transition for the SVG icon */
}

.project-icon .rotate-icon {
  transform: rotate(-45deg); /* Rotate the icon 45 degrees */
}

.project-icon:hover {
  background-color: #00cc7f; /* Change background color on hover */
}

.project-card:hover {
  color: #00cc7f; /* Change icon color to black on hover */
}


.project-line {
  position: absolute;
  bottom: -5px; /* Position the line slightly below the card */
  left: 0; 
  width: 100%;
  height: .3px;
  background-color: gray;
}

.contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  /* padding: 0 10%; */
  background-color: black;
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #ff1111; /* Thumb color */
  border-radius: 0px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}